import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import {
  Card,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Tab,
  Button,
} from "react-bootstrap";

const campaignStatus = [
  {
    key: "all",
    label: "ALL",
  },
  {
    key: "drafts",
    label: "Drafts",
  },
  {
    key: "programmed",
    label: "Scheduled",
  },
  {
    key: "sent",
    label: "Sent",
  },
  {
    key: "starred",
    label: "Starred",
  },
];

const ListCampaign = (props) => {
  const { campaignStore, authStore, overlayStore, history } = props;
  const { campaigns } = campaignStore;
  const [key, setKey] = useState("all");
  const [campaignList, setCampaignList] = useState(campaigns || []);

  useEffect(async () => {
    if (authStore.token) {
      overlayStore.setLoadingSpinner(true);
      await campaignStore.fetchCampaigns(key);
      overlayStore.setLoadingSpinner(false);
    }
  }, [key, authStore.token]);

  useEffect(async () => {
    setCampaignList(campaigns);
  }, [campaigns]);

  // useEffect(async () => {
  //   if (key && key !== "all") {
  //     const filteredCampaigns = campaigns.filter(
  //       (campaign) => campaign.status === "key"
  //     );
  //     setCampaignList(filteredCampaigns);
  //   }else{
  //     setCampaignList(campaigns);
  //   }
  // }, [key, campaigns]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Campaign List</Card.Title>
                <p className="card-category">Here is a list of all campaign</p>
              </Card.Header>
              <Card.Body>
                <Tab.Container
                  id="plain-tabs-example"
                  defaultActiveKey="all"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Nav role="tablist" variant="tabs">
                    {campaignStatus.map((status) => (
                      <Nav.Item key={status.key}>
                        <Nav.Link eventKey={status.key}>
                          {status.label}
                        </Nav.Link>
                      </Nav.Item>
                    ))}
                  </Nav>
                  <Tab.Content>
                    {campaignStatus.map((status) => (
                      <Tab.Pane eventKey={status.key} key={status.id}>
                        <Table className="table-hover table-striped w-full">
                          <thead>
                            <tr>
                              <th>Subject</th>
                              <th>Processed</th>
                              <th>Delivered</th>
                              <th>Opens</th>
                              <th>Opens %</th>
                              <th>Clicks</th>
                              <th>Delivery date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(campaignList || []).map((campaign) => (
                              <tr
                                onClick={() =>
                                  history.push(`/campaign/${campaign.id}`)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <td>{campaign.subject}</td>
                                <td>{campaign.processedCount}</td>
                                <td>{campaign.deliveredCount}</td>
                                <td>{campaign.openedCount}</td>
                                <td>{campaign.openedPercentage}</td>
                                <td>{campaign.clickedCount}</td>
                                <td>{campaign.sendTimeStart}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default inject(
  "campaignStore",
  "authStore",
  "routerHistory",
  "overlayStore"
)(observer(ListCampaign));
