import { inject, observer } from "mobx-react";
import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import ChartistGraph from "react-chartist";
import { VectorMap } from "react-jvectormap";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../components/LoadingSpinner";
import "./Campaigns.css";

const GetCampaign = (props) => {
  const { id } = useParams();
  const { campaignStore, overlayStore } = props;
  const { campaigns } = campaignStore;

  const campaign = campaigns.find(
    (campaign) => String(campaign.id) === String(id)
  );

  if (!campaign)
    return <LoadingSpinner display={overlayStore.displaySpinner} />;

  const {
    title,
    subject,
    sendTimeStart,
    processedCount,
    openedCount,
    clickedCount,
    processedPercentage,
    openedPercentage,
    clickedPercentage,
  } = campaign;

  const unopenedCount = processedCount - openedCount;
  const unopenedPercentage = ((unopenedCount / processedCount) * 100).toFixed(
    2
  );

  let graphValues = {};
  const countries = [];
  (campaign.countries || []).forEach((country, index) => {
    const countryName = country.name || "";
    const countryCode = country.code || "";
    const countryOpened = country.opened || 0;
    const countryClicked = country.clicked || 0;
    countries.push(
      <tr>
        <td>{countryName}</td>
        <td className="text-right">{countryOpened}</td>
        <td className="text-right">{countryClicked}</td>
      </tr>
    );
    graphValues = {
      ...graphValues,
      [countryCode.toUpperCase()]: countryOpened,
    };
  });

  return (
    <>
      <Container fluid>
        <Row>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-email-85 text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Processed</p>
                      <Card.Title as="h4">{processedPercentage}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-key-25 text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Opened</p>
                      <Card.Title as="h4">{openedPercentage} %</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-tag-content text-danger"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Unopened</p>
                      <Card.Title as="h4">{unopenedPercentage} %</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="3" sm="6">
            <Card className="card-stats">
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-tap-01 text-primary"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Clicked</p>
                      <Card.Title as="h4">{clickedPercentage}</Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="8">
            <Card className="regular-table-with-color">
              <Card.Header>
                <Card.Title as="h4">Campaign Summary</Card.Title>
                <p className="card-category">Campaign Basic Information</p>
              </Card.Header>
              <Card.Body className="table-responsive p-0 mt-4">
                <Table className="table-hover">
                  <tbody>
                    <tr className="success">
                      <td>Campaign Title</td>
                      <td>{title}</td>
                    </tr>
                    <tr>
                      <td>Campaign Subject</td>
                      <td>{subject}</td>
                    </tr>
                    <tr className="info">
                      <td>Send Time</td>
                      <td>{sendTimeStart}</td>
                    </tr>
                    <tr>
                      <td>Processed</td>
                      <td>{processedCount}</td>
                    </tr>
                    <tr className="warning">
                      <td>Opened</td>
                      <td>{openedCount}</td>
                    </tr>
                    <tr>
                      <td>Unopened</td>
                      <td>{unopenedCount}</td>
                    </tr>
                    <tr className="danger">
                      <td>Clicked</td>
                      <td>{clickedCount}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <hr></hr>
                <div className="stats"></div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Email Statistics</Card.Title>
                <p className="card-category">Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  className="ct-perfect-fourth"
                  data={{
                    labels: [`${openedPercentage}%`, `${unopenedPercentage}%`],

                    series: [openedPercentage, unopenedPercentage],
                    colors: ["#28a745", "text-warning"],
                  }}
                  type="Pie"
                />
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle mr-1 text-success"></i>
                  Opened <i className="fas fa-circle mr-1 text-warning"></i>
                  Unopened
                </div>
                <hr></hr>
                <div className="stats"></div>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">
                  Global Statistics by Top Locations
                </Card.Title>
                <p className="card-category">All emails that were opened</p>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md="6">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Country Name</th>
                          <th className="text-right">Opened</th>
                          <th className="text-right">Clicked</th>
                        </tr>
                      </thead>
                      <tbody>{countries}</tbody>
                    </Table>
                  </Col>
                  <Col className="ml-auto mr-auto" md="6">
                    <VectorMap
                      map={"world_mill"}
                      backgroundColor="transparent"
                      zoomOnScroll={false}
                      containerStyle={{
                        width: "100%",
                        height: "300px",
                      }}
                      containerClassName="map"
                      regionStyle={{
                        initial: {
                          fill: "#e4e4e4",
                          "fill-opacity": 0.9,
                          stroke: "none",
                          "stroke-width": 0,
                          "stroke-opacity": 0,
                        },
                      }}
                      series={{
                        regions: [
                          {
                            values: graphValues,
                            scale: ["#AAAAAA", "#444444"],
                            normalizeFunction: "polynomial",
                          },
                        ],
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Views</Card.Title>
                <p className="card-category">Bar Chart</p>
              </Card.Header>
              <Card.Body>
                <ChartistGraph
                  type="Bar"
                  data={{
                    labels: [
                      "Windows <br/> Chrome 11",
                      "Feb",
                      "Mar",
                      "Apr",
                      "Mai",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ],
                    series: [
                      [
                        542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756,
                        895,
                      ],
                    ],
                  }}
                  options={{
                    seriesBarDistance: 10,
                    classNames: {
                      bar: "ct-bar ct-azure",
                    },
                    axisX: {
                      showGrid: false,
                    },
                  }}
                  responsiveOptions={[
                    [
                      "screen and (max-width: 640px)",
                      {
                        seriesBarDistance: 5,
                        axisX: {
                          labelInterpolationFnc: function (value) {
                            return value[0];
                          },
                        },
                      },
                    ],
                  ]}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </>
  );
};

export default inject(
  "campaignStore",
  "authStore",
  "routerHistory",
  "overlayStore"
)(observer(GetCampaign));
