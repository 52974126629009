import { makeAutoObservable } from "mobx";
import AuthStore from "./AuthStore";

import {
  initFirebaseBackend,
  getFirebaseBackend,
} from "../helpers/firebase_helper";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

// init firebase backend
initFirebaseBackend(firebaseConfig);

class CampaignStore {
  campaigns = [];
  geoStatistics = [];
  fireBaseBackend = getFirebaseBackend();
  async fetchCampaigns(status) {
    try {
      if (AuthStore.token) {
        const result = await this.fireBaseBackend.getCampaigns();
        console.info('result >>>>>>>>>>>>', result)
        this.campaigns = result.campaigns;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async addBulkCampaigns() {
    try {
      await this.fireBaseBackend.addBulkCampaigns();
    } catch (error) {
      console.error(error);
    }
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new CampaignStore();
