import { makeAutoObservable } from "mobx";

class OverlayStore {
  displaySpinner = false;

  setLoadingSpinner(status) {
    this.displaySpinner = status;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new OverlayStore();
