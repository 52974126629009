import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "mobx-react";

import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0";
import AuthStore from "./stores/AuthStore";
import CampaignStore from "./stores/CampaignStore";
import OverlayStore from "./stores/OverlayStore";
import history from "./utils/history";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "assets/css/demo.css";

import AdminLayout from "layouts/Admin.js";
import App from "./App";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

ReactDOM.render(
  <Provider
    campaignStore={CampaignStore}
    authStore={AuthStore}
    routerHistory={history}
    overlayStore={OverlayStore}
  >
    <Auth0Provider
      authStore={AuthStore}
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      <App />
      {/* <BrowserRouter>
        <Switch>
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
        </Switch>
      </BrowserRouter> */}
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
