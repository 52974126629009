import ListCampaigns from "views/Campaigns/List";
import GetCampaignDetail from "views/Campaigns/Get";

var routes = [
  {
    path: "/campaign/:id",
    layout: "/admin",
    name: "GetCampaignDetail",
    icon: "nc-icon nc-chart-pie-35",
    component: GetCampaignDetail,
  },
  {
    path: "/",
    layout: "/admin",
    name: "Campaigns List",
    icon: "nc-icon nc-single-copy-04",
    component: ListCampaigns,
    showInSidebar: true,
  },
];
export default routes;
