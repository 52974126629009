import React from "react";
import { Container } from "react-bootstrap";

function AdminFooter() {
  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <p className="copyright text-center">
              © <script>document.write(new Date().getFullYear())</script>
              <a href="http://www.incrementl.com">Incrementl</a>, made with love
              for a better web
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
