import { makeAutoObservable } from "mobx";

class AuthStore {
  token = null;
  claims;
  email;

  setToken(token) {
    this.token = token;
  }

  setClaims(claims) {
    this.claims = claims;
    this.email = claims.email;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

export default new AuthStore();
