import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import LoadingSpinner from "./components/LoadingSpinner";

// import { initFirebaseBackend } from "./helpers/firebase_helper"

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// // init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = (props) => {
  const { campaignStore, overlayStore, authStore, history } = props;

  useEffect(async () => {
    if (authStore.token) {
      overlayStore.setLoadingSpinner(true);
      await campaignStore.fetchCampaigns("all");
      // await campaignStore.getCampaigns();
      overlayStore.setLoadingSpinner(false);
    }
  }, [authStore.token]);

  return (
    <>
      <LoadingSpinner display={overlayStore.displaySpinner} />
      <BrowserRouter>
        <Switch>
          <Route path="/" render={(props) => <AdminLayout {...props} />} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default inject(
  "campaignStore",
  "routerHistory",
  "overlayStore",
  "authStore"
)(observer(App));
