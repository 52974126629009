import React from "react";
import Spinner from "react-bootstrap/Spinner";
import "./LoadingSpinner.css";

const LoadingSpinner = ({ display }) => {
  const displayValue = !!display ? "flex" : "none";

  return (
    <div className="spinnerContainer" style={{ display: displayValue }}>
      <Spinner animation="border" />
    </div>
  );
};

export default LoadingSpinner;
